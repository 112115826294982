.app-footer {
    background-color: #efeadf;
    color: #000;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 20px 0;
}

.footer-content h3 {
    font-size: 18px;
    color: #000;
    font-weight: 600;
    margin-bottom: 16px;
}

.footer-content p,
.footer-content ul {
    color: #000;
    margin-bottom: 5px;
}

.footer-content ul {
    list-style-type: none;
    padding: 0;
}

.footer-content ul li {
    margin-bottom: 10px;
}

.footer-content ul li a {
    color: #b0b0b0;
    text-decoration: none;
}

.footer-content ul li a:hover {
    text-decoration: underline;
}

.social-icons a {
    color: #000;
    margin-right: 20px;
    text-decoration: none;
    font-size: 10px;
}

.social-icons a:hover {
    color: #1890ff;
}


.social-icons a.twitter {
    color: #1DA1F2;
}

.social-icons a.linkedin {
    color: #0077B5;
}

.social-icons a.insta {
    color: #4f5bd5;
}

.social-icons a.facebook {
    color: #1877F2;
}

.social-icons a:hover {
    opacity: 0.8;
}

.footer-bottom {
    text-align: center;
    color: #000;
    padding-bottom: 30px;
    padding-top: 30px;
    border-top: 1px solid #ccc;
}

.footer-bottom p {
    margin: 0;
}

.ant-layout-footer {
    padding: 0;
}

.ant-row {
    justify-content: space-between;
    padding-bottom: 30px;
}