.news-card .ant-modal-body .detailed-summary-list {
    padding-left: 20px;
    list-style-type: disc;
}

.news-card .ant-modal-body .detailed-summary-list li {
    font-size: 13px; /* Adjust font size to match your design */
}

.comments-section {
    margin-top: 20px;
}

.comments-list {
    max-height: 200px;
    overflow-y: auto;
    padding-right: 10px; /* Adjust for scrollbar width */
    padding-left: 5px;
    margin-bottom: 20px; /* Add margin-bottom for better spacing */
}

.comment {
    border-bottom: 1px solid #e8e8e8;
    padding: 10px 0;
    display: flex; /* Add flexbox for better alignment */
    flex-direction: column; /* Ensure comments stack vertically */
}

.comment p {
    margin: 0;
    font-size: 14px; /* Adjust font size for better readability */
}

.comment small {
    color: #888;
    margin-top: 5px;
    font-size: 12px; /* Adjust font size for better readability */
}

/* Your existing CSS */
.news-cards {
    padding: 120px 0;
}
.news-card {
    height: 100%;
    transition: box-shadow 0.3s;
}

.news-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible) {
    color: #000000;
}

.ant-tabs .ant-tabs-tab:hover,
.ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible), 
.ant-tabs .ant-tabs-tab-btn:active, 
.ant-tabs .ant-tabs-tab-remove:active {
    color: #cfc6b1;
}

.ant-tabs .ant-tabs-ink-bar {
    background: #cfc6b1;
}

.ant-tabs >.ant-tabs-nav .ant-tabs-nav-wrap, 
.ant-tabs >div>.ant-tabs-nav .ant-tabs-nav-wrap {
    justify-content: center;
}

.card {
    border: 0;
    font-family: "Open Sans", sans-serif;
    border-top-left-radius: 20px;
    border-radius: 20px;
}

.card-sources > .card-text:first-child {
    margin-bottom: 8px;
}

.card .card-body {
    padding-left: 15px;
    padding-right: 15px;
}

.card .card-body .card-title {
    font-size: 25px;
    letter-spacing: 0.6px;
    line-height: 30px;
    margin-bottom: 10px;
}

.news-card .card-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    color: #575656;
    margin-bottom: 0;
}

.news-card .card-text svg {
    color: #000;
}

.image-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.lazy-load-image-loaded img {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    min-height: 255px;
    max-height: 255px;
    object-fit: cover;
}

.btn.btn-link {
    padding: 0;
    color: #575656;
    text-transform: none;
    font-weight: bold;
    text-decoration: none;
    font-size: 14px;
    line-height: normal;
    position: relative;
    top: -1px;
    margin-left: 0;
}

.btn.btn-link:hover {
    text-decoration: underline;
}

.card-bottom {
    display: flex;
    align-items: center;
}

.card-bottom .up-down-vote {
    margin-right: 15px;
}

.card-bottom .up-down-vote > a,
.share-icon {
    color: #000;
    text-decoration: none;
}

.card-bottom .up-down-vote > a:hover {
    opacity: 0.5;
}

.share-icon:hover {
    text-decoration: underline;
    color: #000;
}

.card-sources {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.card-sources > a {
    text-decoration: none;
    color: #000;
}

.card-sources > a:hover {
    opacity: 0.5;
}

.card-sources .share-icon {
    margin-left: 15px;
}

.btn-check:checked+.btn, 
.btn.active, 
.btn.show, 
.btn:first-child:active, 
:not(.btn-check)+.btn:active {
    color: #000000;
}

.ant-modal .ant-modal-header {
    margin-bottom: 30px;
}

.ant-modal-header .ant-modal-title {
    font-size: 20px;
    text-align: left;
    line-height: 22px;
    padding-right: 10px;
}

.ant-modal-body h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
}

.ant-modal-body p {
    font-size: 13px;
    margin-bottom: 15px;
}

.ant-modal-body ul {
    padding-left: 20px; /* Ensure padding is set for detailed summary */
    list-style-type: disc; /* Ensure bullets are displayed */
}

.ant-modal-body ul li {
    font-size: 13px;
}

.ant-modal-body ul li strong {
    font-size: 13px;
    font-weight: 500;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #000;
    border-color: #000;
    opacity: 0.6;
}

.ant-collapse-header-text {
    font-size: 13px;
}

.ant-collapse-content-box > p {
    margin-bottom: 0;
}

@media (max-width: 600px) {
    .card {
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    }

    .ant-tabs-content-holder .row > div {
        padding-left: 10px;
        padding-right: 10px;
    }

    .card .card-body .card-title {
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 10px;
    }

    .ant-modal .ant-modal-content {
        padding: 20px 15px;
    }

    .lazy-load-image-loaded img {
        min-height: 100%;
        max-height: 300px;
    }

    .news-cards {
        padding-bottom: 60px;
    }

    .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
        margin: 0 0 0 20px;
    }

    .ant-modal-header .ant-modal-title {
        padding-right: 10px;
    }
}

.ant-dropdown {
    min-width: 125px !important;
}

.ant-dropdown-menu {
    padding: 10px 0 !important;
}

.ant-dropdown-menu-title-content {
    text-align: center;
}

.ant-dropdown-menu-item {
    border-bottom: 1px solid #ccc;
    border-radius: 0;
}

.ant-dropdown-menu-item:last-child {
    border-bottom: 0;
}

.ant-dropdown-menu .ant-dropdown-menu-item {
    border-radius: 0 !important;
}

.ant-dropdown-menu-title-content > svg:hover {
    opacity: 0.8;
}

.ant-dropdown-menu-item.facebook svg {
    color: #1877F2;
}

.ant-dropdown-menu-item.twitter svg {
    color: #1DA1F2;
}

.ant-dropdown-menu-item.reddit svg {
    color: #FF4500;
}

.ant-dropdown-menu-item .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
}

.ant-dropdown-menu-item .ant-dropdown-menu-title-content > span {
    font-size: 12px;
    margin-left: 12px;
    font-weight: 600;
}

.ant-dropdown-menu::before {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
    content: '';
    position: absolute;
    bottom: -10px;
    left: 15px;
}

.ant-modal-body .ant-btn-primary,
.ant-dropdown-trigger.share-icon {
    text-decoration: none;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 20px;
}

.ant-dropdown-trigger.share-icon {
    background: #fff;
    border: 2px solid #000;
    color: #000;
    transition: 0.3s all ease;
}

.ant-dropdown-trigger.share-icon:hover {
    opacity: 0.6;
}

.full-article {
    transition: 0.2s all ease;
}

.full-article:hover {
    background-color: #000 !important;
    border-color: #fff !important;
    color: #fff !important;
    opacity: 0.6 !important;
}

.read-more > a {
    text-decoration: none;
    color: #000;
    font-weight: 600;
}

.read-more > a:hover {
    opacity: 0.5;
}

.ant-modal .ant-modal-footer {
    margin-top: 30px;
}

.card-bottom .ant-btn-link {
    color: #000;
    padding: 0;
    display: inline-block;
}

.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
    color: #000;
    opacity: 0.5;
}

.view-count:hover {
    opacity: 0.5;
}
