.logo {
    line-height: normal;
    font-weight: 700;
    font-size: 18px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 80px;
    background: #fff;
    transition: background-color 0.3s, box-shadow 0.3s;
    z-index: 1000;
    border-bottom: 1px solid #eee;
}

.header.scrolled {
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.header .date {
    color: #000;
    transition: color 0.3s;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: normal;
}

.header.scrolled .date {
    color: #000;
}

.header .logo img {
    height: 80px; 
}

.logo {
    cursor: pointer;
}

.ant-btn-primary {
    color: #fff;
    background: #000;
    box-shadow: none;
    border-color: #000;
    font-size: 15px;
    font-weight: 400;
    transition: 0.5s all ease;
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background: #000;
    color: #fff;
    border-color: #000;
    opacity: 0.6;
}

@media (max-width: 600px) {
    .header {
        padding: 0 20px;
    }

    .ant-btn-primary {
        padding-left: 10px;
        padding-right: 10px;
        height: 30px;
        font-size: 13px;
    }
}

.ant-form-item-control-input-content .ant-btn {
    height: 48px;
}

.logo-img {
    height: 65px;
    cursor: pointer;
}

.logo-img img {
    height: 100%;
}