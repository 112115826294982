.login-form {
    min-width: 500px;
    padding: 120px 0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    padding: 35px 25px;
    margin-top: 150px;
}

.login-form-button {
    width: 100%;
}

.ant-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.heading {
    font-size: 25px;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: 700;
    letter-spacing: 2px;
    text-align: center;
}

.ant-input,
.ant-input-outlined {
    height: 50px;
}

:where(.css-dev-only-do-not-override-j9bb5n).ant-input-outlined:focus, :where(.css-dev-only-do-not-override-j9bb5n).ant-input-outlined:focus-within,
:where(.css-dev-only-do-not-override-j9bb5n).ant-input-outlined:hover {
    border-color: #efeade;
}

.ant-input-password > input {
    position: relative;
    top: -5px;
}

:where(.css-dev-only-do-not-override-j9bb5n).ant-form-item .ant-form-item-label >label {
    font-weight: 500;
}

.ant-form-item-control-input-content .ant-btn {
    width: 100%;
}

.login-info-text {
    margin-top: 20px;
    text-align: center;
    color: #000;
}

.login-info-text span {
    cursor: pointer;
    transition: 0.2s all ease;
    text-decoration: underline;
}

.login-info-text span:hover {
    text-decoration: underline;
}


@media (max-width: 600px) {
    .login-form {
        min-width: 100%;
        padding: 35px 15px;
    }
}

.ant-input-outlined:hover,
.ant-input-outlined:focus,
.ant-input-outlined:focus-within {
    border-color: #000;
}