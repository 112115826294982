.collect-email-form {
    min-width: 500px;
    padding: 120px 0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    padding: 35px 25px;
    margin-top: 150px;
}

.collect-email-form-button {
    width: 100%;
}

.ant-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.heading {
    font-size: 25px;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: 700;
    letter-spacing: 2px;
    text-align: center;
}

.ant-input,
.ant-input-outlined {
    height: 50px;
}

:where(.css-dev-only-do-not-override-j9bb5n).ant-input-outlined:focus, :where(.css-dev-only-do-not-override-j9bb5n).ant-input-outlined:focus-within,
:where(.css-dev-only-do-not-override-j9bb5n).ant-input-outlined:hover {
    border-color: #efeade;
}

.ant-form-item-control-input-content .ant-btn {
    width: 100%;
}

@media (max-width: 600px) {
    .collect-email-form {
        min-width: 100%;
        padding: 35px 15px;
    }
}
